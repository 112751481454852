import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    from_number: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.from_name.trim()) {
      newErrors.from_name = 'Name is required';
    }

    if (!formData.from_email.trim()) {
      newErrors.from_email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.from_email)) {
      newErrors.from_email = 'Please enter a valid email address';
    }

    if (formData.from_number && !/^\+?[\d\s-()]{10,}$/.test(formData.from_number)) {
      newErrors.from_number = 'Please enter a valid phone number';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    
    try {
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );
      
      console.log(result.text);
      setMessage('Message Sent Successfully!');
      setMessageType('success');
      setFormData({
        from_name: '',
        from_email: '',
        from_number: '',
        message: ''
      });
    } catch (error) {
      console.log(error.text);
      setMessage('Failed to Send Message. Please try again.');
      setMessageType('error');
    } finally {
      setIsSubmitting(false);
    }

    setTimeout(() => {
      setMessage('');
      setMessageType('');
    }, 15000);
  };

  return (
    <div className="section contact-section" id="contact">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 info site-heading">
            <h4 className="section-heading-center">CONTACT</h4>
            <h2 className="section-subtitle-center">I'd like to hear from you!</h2>
          </div>
        </div>

        {message && (
          <div 
            className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'}`}
            role="alert"
          >
            {message}
          </div>
        )}

        <form 
          id="contact-form" 
          onSubmit={sendEmail} 
          className="contact-form"
          noValidate
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <input 
                  className={`form-control bg-gray ${errors.from_name ? 'is-invalid' : ''}`}
                  name="from_name"
                  placeholder="Your Name"
                  type="text"
                  value={formData.from_name}
                  onChange={handleChange}
                  aria-invalid={errors.from_name ? 'true' : 'false'}
                  aria-describedby={errors.from_name ? 'name-error' : undefined}
                />
                {errors.from_name && (
                  <div id="name-error" className="invalid-feedback">
                    {errors.from_name}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <input 
                  className={`form-control bg-gray ${errors.from_email ? 'is-invalid' : ''}`}
                  name="from_email"
                  placeholder="Your Email*"
                  type="email"
                  value={formData.from_email}
                  onChange={handleChange}
                  aria-invalid={errors.from_email ? 'true' : 'false'}
                  aria-describedby={errors.from_email ? 'email-error' : undefined}
                />
                {errors.from_email && (
                  <div id="email-error" className="invalid-feedback">
                    {errors.from_email}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <input 
                  className={`form-control bg-gray ${errors.from_number ? 'is-invalid' : ''}`}
                  name="from_number"
                  placeholder="Your Phone Number"
                  type="tel"
                  value={formData.from_number}
                  onChange={handleChange}
                  aria-invalid={errors.from_number ? 'true' : 'false'}
                  aria-describedby={errors.from_number ? 'phone-error' : undefined}
                />
                {errors.from_number && (
                  <div id="phone-error" className="invalid-feedback">
                    {errors.from_number}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group comments">
                <textarea 
                  className={`form-control bg-gray ${errors.message ? 'is-invalid' : ''}`}
                  name="message"
                  placeholder="Your Message *"
                  value={formData.message}
                  onChange={handleChange}
                  aria-invalid={errors.message ? 'true' : 'false'}
                  aria-describedby={errors.message ? 'message-error' : undefined}
                ></textarea>
                {errors.message && (
                  <div id="message-error" className="invalid-feedback">
                    {errors.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-left">
              <button 
                type="submit" 
                className="btn-theme"
                disabled={isSubmitting}
                aria-busy={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'} 
                <i className="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
