import React from 'react';

const projectsData = [
  {
    id: 0,
    name: "Python: Thesis Project",
    date: "2021", 
    desc: "Developed a Python-based noise reduction system using Google Cloud APIs. Focused on improving speech recognition by reducing background noise.",
    image: "./assets/img/project1.png",
    link: 'https://drive.google.com/file/d/1t5DSBZ7ApxAad6e5M2wxv0_jUZxmOJtg/view?usp=sharing'
  },
  {
    id: 1,
    name: "R: Bellabeat Project",
    date: "2022",
    desc: "Analyzed FitBit user data using RMarkdown to extract insights on user activity. Findings helped inform Bellabeat's marketing strategy.",
    image: "./assets/img/project3.png",
    link: 'https://www.kaggle.com/code/valkyval/bellabeat-capstone-project'
  }
];

const Portfolio = () => {
  // Sort projects by date in descending order
  const sortedProjects = [...projectsData].sort((a, b) => b.date - a.date);

  return (
    <div className="section projects-area bg-gray default-padding" id="portfolio">
      <div className="container">
        {/* Section Header */}
        <div className="row justify-content-center text-center">
          <div className="col-lg-6 col-md-12 info site-heading">
            <h4 className="section-heading-center">PORTFOLIO</h4>
            <h2 className="section-subtitle-center">Explore My Projects</h2>
          </div>
        </div>

        {/* Project Cards */}
        <div className="project-items-area">
          <div className="row justify-content-center">
            {sortedProjects.map((project) => (
              <div className="col-lg-4 col-md-6 col-sm-12 project-item" key={project.id}>
                <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-card">
                  <div className="card shadow-lg border bg-white rounded overflow-hidden">
                    <div className="thumb project-thumb position-relative">
                      <img src={project.image} alt={project.name} className="img-fluid rounded-top" />
                      <div className="open-link-icon">
                        <i className="fas fa-external-link-alt"></i>
                      </div>
                    </div>

                    <div className="card-body text-left p-4">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5 className="project-title mb-0">{project.name}</h5>
                        <span className="project-date badge bg-light text-dark">{project.date}</span>
                      </div>
                      <p className="project-desc mb-0">{project.desc}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
