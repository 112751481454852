import React from 'react';

const About = () => {
  return (
    <div className="section about-container bg-gray" id="about">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Section - Hidden on Small Screens */}
          <div className="col-lg-6 d-none d-lg-block text-center">
            <img
              src="assets/img/illustration/about-img.png"
              alt="About Thumb"
              className="about-img"
            />
          </div>

          {/* Info Section */}
          <div className="col-lg-6 col-md-12 info site-heading">
            <h4 className="section-heading-left">About Me</h4>
            <h3 className="section-subtitle-left">I'm Valentina Deed, based in Cheshire, Northwest of England, UK</h3>
            <div className="about-text">
              <p>As a <strong className="no-break">Business Intelligence Developer</strong>, I'm passionate about making data work for you. I create intuitive dashboards and automated solutions that transform complex data into clear insights, helping your business make smarter decisions. My approach combines technical expertise with practical solutions that drive real results.</p>
            </div>
            {/* Bullet Points Section */}
            <ul className="about-list">
              <li>
                <div className="title-container">
                  <h5 className="heading-left"><strong className="no-break">Power BI & Python</strong></h5>
                </div>
                <p>Crafting powerful visualizations and automated solutions that tell your data story.</p>
              </li>
              <li>
                <div className="title-container">
                  <h5 className="heading-left"><strong className="no-break">Data Warehousing & SQL</strong></h5>
                </div>
                <p>Building scalable foundations for your data-driven success.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
