import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-area" role="contentinfo">
      <div className="container">
        <div className="footer-content">
          {/* Footer Text (Centered) */}
          <div className="footer-text">
            <div className="copyright-text">&copy; 2025 VDeed.co.uk<br />All Rights Reserved</div>
          </div>

          {/* LinkedIn Icon (Right-Aligned on Desktop, Above on Mobile) */}
          <div className="footer-icon">
            <a
              href="https://www.linkedin.com/in/valentina-deed/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin-icon"
              aria-label="Visit VDeed's LinkedIn Profile"
              title="Connect with VDeed on LinkedIn"
            >
              <i className="fab fa-linkedin" aria-hidden="true"></i>
              <span className="sr-only">LinkedIn</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
