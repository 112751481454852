import React from 'react';

const Hero = () => {
  return (
    <div className="hero-container" id="hero">
      {/* Hero Section */}
      <div className="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h1 style={{ color: '#f4f4f4', fontSize: '3.5rem', fontWeight: '700', lineHeight: '1.2', marginBottom: '20px' }}>
                Data-Driven Decisions Made Simple
              </h1>
              <p style={{ color: '#f4f4f4', fontSize: '1.2rem', marginTop: '25px', lineHeight: '1.8', maxWidth: '600px', opacity: '0.9' }}>
                Turning complex data into clear insights. 
                Building powerful dashboards that drive success.
              </p>
              <div style={{ marginTop: '35px', textAlign: 'left' }}>
                <a href="#contact" className="btn-theme">
                  Let's Work Together
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-5 d-flex justify-content-center hero-illustration">
              <img 
                src="/assets/img/illustration/5.png" 
                alt="Hero Illustration" 
                style={{ transform: "scaleX(-1)", maxWidth: '100%', height: 'auto' }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Carousel Section */}
      <div className="carousel-section">
        <div className="container">
          <div className="row d-flex flex-wrap justify-content-center align-items-center">
            {/* Skills Icons */}
            {[ 
              { src: "/assets/img/icons8-python.svg", alt: "Python", label: "Python" },
              { src: "/assets/img/icons8-power-bi-logo.svg", alt: "Power BI", label: "Power BI" },
              { src: "/assets/img/icons8-mysql.svg", alt: "MySQL", label: "MySQL" },
              { src: "/assets/img/icons8-postgresql.svg", alt: "PostgreSQL", label: "PostgreSQL" },
              { src: "/assets/img/icons8-git.svg", alt: "Git", label: "Git" },
              { src: "/assets/img/icons8-excel.svg", alt: "Excel", label: "Excel" },
            ].map((item, index) => (
              <div key={index} className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                <div className="feature-single-item text-center">
                  <div className="thumb">
                    <img src={item.src} alt={item.alt} className="skill-icon" />
                  </div>
                  <h6 className="skill-label">{item.label}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
