import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => {
  return (
    <Helmet>
      <title>VDeed - Professional Portfolio</title>
      <meta name="description" content="Welcome to VDeed's professional portfolio. Explore my work, skills, and experience in web development and design." />
      <meta name="keywords" content="portfolio, web development, design, React, JavaScript, HTML, CSS" />
      <meta name="author" content="VDeed" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://vdeed.com/" />
      <meta property="og:title" content="VDeed - Professional Portfolio" />
      <meta property="og:description" content="Welcome to VDeed's professional portfolio. Explore my work, skills, and experience in web development and design." />
      <meta property="og:image" content="assets/img/VDeed logo blu.png" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://vdeed.com/" />
      <meta property="twitter:title" content="VDeed - Professional Portfolio" />
      <meta property="twitter:description" content="Welcome to VDeed's professional portfolio. Explore my work, skills, and experience in web development and design." />
      <meta property="twitter:image" content="assets/img/VDeed logo blu.png" />

      {/* Additional SEO tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#000000" />
      <link rel="canonical" href="https://vdeed.com/" />
    </Helmet>
  );
};

export default Head;
