import React from 'react';

const servicesData = [
  {
    name: 'Data Preparation',
    icon: 'fas fa-trash-alt',
    description: 'Cleaning and structuring data for analysis'
  },
  {
    name: 'Transformation & ETL',
    icon: 'fas fa-sync-alt',
    description: 'Building efficient data pipelines'
  },
  {
    name: 'SQL Database',
    icon: 'fas fa-database',
    description: 'Database design and optimization'
  },
  {
    name: 'Data Visualization',
    icon: 'fas fa-chart-line',
    description: 'Creating insightful dashboards'
  },
  {
    name: 'Flow Automation',
    icon: 'fas fa-cogs',
    description: 'Automating business processes'
  },
  {
    name: 'Predictive Analytics',
    icon: 'fas fa-chart-area',
    description: 'Advanced analytics and forecasting'
  },
];

const Skills = () => {
  return (
    <div className="section skills-container bg-white" id="skills">
      <div className="container">
        {/* Header Section */}
        <div className="row">
          <div className="text-wrapper">
            <h4 className="section-heading-left">WHY CHOOSE ME?</h4>
            <h2 className="section-subtitle-left">Mastering Data, Analytics & Business Processes</h2>
            <p>
              I help businesses streamline workflows and uncover hidden trends through advanced analytics.
              Whether optimizing data pipelines or crafting dashboards, I turn raw data into actionable insights.
            </p>
          </div>

          <div className="image-wrapper">
            <img src="assets/img/illustration/skills-img.png" alt="Data Illustration" className="skills-image" />
          </div>
        </div>

        {/* Services Grid */}
        <div className="services-grid">
          {servicesData.map((service, index) => (
            <div key={index} className="service-box">
              <div className="text-content">
                <h3 className="heading-left">{service.name}</h3>
                <p>{service.description}</p>
              </div>
              <i className={service.icon}></i>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
