import React, { useState, useEffect } from 'react';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Close menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.navbar') && menuOpen) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [menuOpen]);

  return (
    <header role="banner">
      <nav className="navbar" role="navigation" aria-label="Main navigation">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(!menuOpen);
              }}
              aria-expanded={menuOpen}
              aria-controls="navbar-collapse"
              aria-label={menuOpen ? "Close menu" : "Open menu"}
            >
              <i className={`fa ${menuOpen ? 'fa-times' : 'fa-bars'}`} aria-hidden="true"></i>
            </button>
            <a 
              className="navbar-brand" 
              href="#hero"
              onClick={() => setMenuOpen(false)}
              aria-label="Home"
            >
              <img
                src="assets/img/VDeed logo blu.png"
                alt="VDeed Logo"
                className="logo"
                width="150"
                height="50"
              />
            </a>
          </div>
          <div 
            id="navbar-collapse"
            className={`navbar-collapse ${menuOpen ? 'show' : ''}`}
            role="menubar"
            aria-label="Main menu"
          >
            <ul className="nav navbar-nav navbar-right" role="menu">
              <li role="none">
                <a href="#hero" onClick={() => setMenuOpen(false)} role="menuitem">Home</a>
              </li>
              <li role="none">
                <a href="#about" onClick={() => setMenuOpen(false)} role="menuitem">About</a>
              </li>
              <li role="none">
                <a href="#skills" onClick={() => setMenuOpen(false)} role="menuitem">Skills</a>
              </li>
              <li role="none">
                <a href="#portfolio" onClick={() => setMenuOpen(false)} role="menuitem">Portfolio</a>
              </li>
              <li role="none">
                <a href="#contact" onClick={() => setMenuOpen(false)} role="menuitem">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
